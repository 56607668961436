<template>
  <div class="record">
    <h5header :name="$t('withdraw.desc31')" :right="false" />
    <div class="record-con">
      <van-list
        v-model:loading="loading"
        :loading-text="$t('quotes.desc20')"
        :finished="finished"
        @load="onLoad"
      >
        <van-cell v-for="item in list" :key="item.id">
          <div class="record-list" @click="handlerInfo(item)">
            <div class="rl-til">{{ $t("asset.desc67") }}</div>
            <div class="rl-list">
              <div class="rl-item">
                <span>{{ $t("withdraw.desc34") }}</span>
                <span>{{ common.cutXiaoNum1(item.amount) }} {{ item.feeCoinName }}</span>
              </div>
              <div class="rl-item">
                <span>{{ $t("withdraw.desc37") }}</span>
                <span>{{
                  dealAuditStatus(item.auditStatusCode, item.status)
                }}</span>
              </div>
              <div class="rl-item">
                <span>{{ $t('record.desc5') }}</span>
                <span>{{ dealDate(item.datetime) }}</span>
              </div>
            </div>
          </div>
        </van-cell>
      </van-list>
      <div class="no-data" v-if="showNull">
        <img src="../../public/images/no-data.png" alt="" />
        <span>{{ $t("record.desc13") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import h5header from "@/componets/h5header.vue";
export default {
  components: {
    h5header,
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
      showNull: false,
    };
  },
  methods: {
    onLoad() {
      setTimeout(() => {
        this.$post(this.URL.assets.withdrawRecord, {
          page: this.page,
          pageSize: 10,
          coinId: this.$route.query.id,
        }).then((res) => {
          if (res.code == 0) {
            res.data.list.forEach(e => {
              e.datetime  = this.common.formatDate(e.txDate);
          });
            if (this.page == 1) {
              this.list = res.data.list;
            } else {
              this.list = this.list.concat(res.data.list);
            }
            this.page++;
            this.loading = false;
            if (this.list.length == 0) {
              this.showNull = true;
            }
            if (this.list.length >= parseFloat(res.data.page.count)) {
              this.finished = true;
            }
          }
        });
      }, 500);
    },
    dealDate(str) {
      let arr = str.split(' ')
      let arr1 = arr[0].split('-')
      let arr2 = arr[1].split(':')
      return arr1[1] + '-' + arr1[2] + ' ' + arr2[0] + ':' + arr2[1]
    },
    dealAuditStatus(auditStatusCode, status) {
      if (auditStatusCode === 1) {
        switch (status) {
          case 0:
            return this.$t("withdraw.desc39");
          case 1:
            return this.$t("withdraw.desc40");
          case 2:
            return this.$t("withdraw.desc41");

          default:
            break;
        }
      } else {
        switch (auditStatusCode) {
          case 0:
            return this.$t("withdraw.desc24");
          case 2:
            return this.$t("withdraw.desc25");
        }
      }
    },
    handlerInfo(info) {
      localStorage.setItem("withdrawRecordInfo", JSON.stringify(info));
      this.$router.push("/withdraw/record/detail");
    },
  },
};
</script>

<style lang="less" scoped>
.record {
  padding: 0 16px;
  min-height: 100vh;
  background: url("../../public/images/new/h5bg.png") center no-repeat;
  background-size: 100% 100%;
}
.no-data {
  width: 100%;
  padding: 130px 0;

  img {
    display: block;
    width: 120px;
    height: 120px;
    margin: 0 auto 16px;
  }

  span {
    display: block;
    margin: 0 auto;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #a1a1a1;
  }
}
.record-con {
  padding: 56px 0;

  ::v-deep .van-list {
    border-radius: 8px;
    background: #181818;
    padding: 0 14px;

    .van-cell {
      padding: 15px 0;
      background: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.06);
      &::after {
        border-bottom: none;
      }
      .record-list {
        .rl-til {
          font-size: 14px;
          font-weight: 500;
          color: #fff;
          margin-bottom: 16px;
        }
        .rl-list {
          display: flex;
          justify-content: space-between;
          .rl-item {
            // flex: 1;
            span {
              display: block;
              font-size: 12px;
              font-weight: 400;
              color: #bbbbbb;
              &:last-child {
                font-size: 14px;
                font-weight: 500;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
</style>
